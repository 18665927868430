// TEST
let TestBaseUrl: string = "https://beta.rasasjewelry.com/api/api/";
let TestActualUrl: string = "https://beta.rasasjewelry.com/";
// TEST

let isLive: boolean = false;

// LIVE
// let LiveBaseUrl: string = "https://rasasjewelry.com/api/api/";
// let LiveActualUrl: string = "https://rasasjewelry/";
// LIVE
export const environment = {
  production: true,
  isLive: isLive,
  baseUrl: TestBaseUrl,
  actualUrl: TestActualUrl,
  appName: "RasasWeb",
  imageBaseUrl: TestBaseUrl + "File/",
  imageBaseUrlDownload: TestBaseUrl + "File/download/"
};
