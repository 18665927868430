import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { PaymentInfoService } from '../payment-info.service';

@Component({
  selector: 'app-payment-status-dialog',
  templateUrl: './payment-status-dialog.component.html',
  styleUrls: ['./payment-status-dialog.component.css']
})
export class PaymentStatusDialogComponent implements OnInit {

  hyperPayMode: "register" | "pay" = null
  checkOutId = null;
  statusRes;

  constructor(
    public dialogRef: MatDialogRef<PaymentStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public app: AppService,
    private service: PaymentInfoService,
  ) { }

  ngOnInit(): void {
    this.app.showLoading();
    this.hyperPayMode = this?.data?.mode
    this.checkOutId = this?.data?.checkOutId
    if (this.hyperPayMode == 'register') {
      this.getSaveCardStatus();
    } else if (this.hyperPayMode == 'pay') {
      this.getPaymentStatus();
    }
  }

  getSaveCardStatus() {
    this.service.getSaveCardStatus(this?.checkOutId).subscribe(
      res => {
        this.statusRes = res
        this.app.dismissLoading();
      }
    )
  }

  getPaymentStatus() {
    this.service.getPaymentStatus(this?.checkOutId).subscribe(
      res => {
        this.statusRes = res
        if(res.errorCode == 0){
          this.app.cleanCart()
        }
        this.app.dismissLoading();
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

}
