<div class="d-flex justify-content-between px-4 align-items-center">
    <div></div>
    <div>
        <p class="enterSerialNumberP m-0">{{ 'profile.enterSErial' | translate }}</p>
    </div>
    <div>
        <mat-icon class="xClose" (click)="close()">close</mat-icon>
    </div>
</div>
<hr class="hrC my-3">
<div>

    <p class="enterPl m-0 text-center px-4 my-4">{{ 'profile.youHave2' | translate }}</p>

    <div class="px5Res">
        <div class="px5Res">
            <button (click)="routeToCertiList()" class="btn routeToCertiBtn my-3">{{ 'profile.myCerti' | translate }}</button>
        </div>
    </div>

</div>