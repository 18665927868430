<div [dir]="this.app.getUserDire()" data-aos="fade-in" data-aos-duration="800" data-aos-delay="500"
    style="height: auto;">

    <!-- *ngIf="statusRes?.errorCode == 0" -->

    <p *ngIf="statusRes?.errorCode == 0 && hyperPayMode == 'pay'" class="mainPfont text-center">{{ 'payment.paymentSuccessful' | translate }}</p>
    <p *ngIf="statusRes?.errorCode == 0 && hyperPayMode == 'register'" class="mainPfont text-center">{{ 'payment.addedSuccessful' | translate }}</p>
    <p *ngIf="statusRes?.errorCode != 0" class="mainPfont text-center">{{ 'payment.somethingWentWrong' | translate }}</p>

    <div class="d-flex justify-content-center my-4">
        <mat-icon class="successIcon" *ngIf="statusRes?.errorCode == 0">check_circle_outlined</mat-icon>
        <mat-icon class="errorIcon" *ngIf="statusRes?.errorCode != 0">error_outlined</mat-icon>
    </div>

    <div class="dialog_container" *ngIf="statusRes?.errorCode != 0">
        <p class="text-center">{{statusRes?.errorMessage}}</p>
    </div>

    <div class="d-flex justify-content-center">
        <button (click)="close()" class="btn closeBtn">{{ 'payment.close' | translate }}</button>
    </div>


</div>