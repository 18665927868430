import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonService } from '../common.service';
import { enterFromRightLeaveToLeftAnimation, fadeInOutAnimationJS } from 'src/app/animations/animations';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-headerboot',
  templateUrl: './headerboot.component.html',
  styleUrls: ['./headerboot.component.css'],
  animations: [fadeInOutAnimationJS, enterFromRightLeaveToLeftAnimation(localStorage.Language)],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderbootComponent implements OnInit {

  selectedLang

  constructor(
    public app: AppService,
    public router: Router,
    public appCompon: AppComponent,
    public route: ActivatedRoute,
    private service: CommonService,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getCounties();
    }

    setTimeout(() => {
      this.selectedLang = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
    }, 100);
    this.selectedLang = this!.app!.getUserLanguage()
    // if (isPlatformBrowser(this.platformId)) {
    //   this.addScrollListener();
    // }

    // debounce
    this.autoComplete.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(results => {
        if (this.autoComplete.value) {
          this.productsAutoComplete();
        }
      });
    // debounce
  }

  // ngOnDestroy(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.removeScrollListener();
  //   }
  // }

  // Btns
  heartBtn() {
    this.closeNavBarMobile()
    if (this?.app?.getUserData()) {
      this.router.navigate(['/' + this?.app?.languageCode + '/favorites']);
    } else if (!this?.app?.getUserData()) {
      this.notLoggedInDialog();
    }
  }
  personBtn() {
    if (this?.app?.getUserData()) {

    } else if (!this?.app?.getUserData()) {
      this.notLoggedInDialog();
    }
  }
  bagBtn() {
    this.closeNavBarMobile()
    this?.app?.openCart();
    // because Cart is not logged in feature
    return
    if (this?.app?.getUserData()) {
    } else if (!this?.app?.getUserData()) {
      this.notLoggedInDialog();
    }
  }
  // Btns


  // AutoComplete
  autoCompleteLoading: boolean = false
  afterTheFirstTime: boolean = false
  autoRes;
  autoComplete = new FormControl('');
  productsAutoComplete() {
    this.app.removeScrollPosFromSessionStorage();
    this.autoCompleteLoading = true
    this.autoRes = null
    let request = new FormData();
    request.append("SearchFor", this?.autoComplete?.value);
    this.service.autoCompleteProducts(request).subscribe(
      res => {
        if (res.errorCode == 0) {
          this.autoRes = res.data
          this.afterTheFirstTime = true
          this.autoCompleteLoading = false
        } else {
          this.app.errorMsg(res.errorMessage)
          this.autoCompleteLoading = false
        }
      }
    )
  }
  goToDetails(id, type, name) {
    this.app.removeScrollPosFromSessionStorage();
    switch (type) {
      case 1:

        break;
      case 2:
        if (this.router.url.includes('/products/')) {
          this.app.routeToProducts(name, null)
          setTimeout(() => {
            window.location.reload();
          }, 100);
        } else {
          this.app.routeToProducts(name, null)
        }
        break;
      case 3:
        if (this.router.url.includes('/products/')) {
          this.app.routeToProducts(null, name)
          setTimeout(() => {
            window.location.reload();
          }, 100);
        } else {
          this.app.routeToProducts(null, name)
        }
        break;
      case 4:
        if (this.router.url.includes('/product-details/')) {
          this.router.navigate([`/${this?.app?.languageCode}/product-details/${id}`]);
          setTimeout(() => {
            window.location.reload();
          }, 100);
        } else {
          this.router.navigate([`/${this?.app?.languageCode}/product-details/${id}`]);
        }
        break;
    }
    this.autoRes = null
    this.autoComplete.setValue('')
  }
  // AutoComplete

  // TypeMenu
  selectedSubHeader = 'all'
  typesMenu: boolean = false

  openTypesMenu() {
    this.typesMenu = true

    if (!this?.categories?.length && this.categories0LengthAfterRequest == false) {
      this.getCategories();
    }

    setTimeout(() => {
      if (!this?.typesData?.length && this.typesData0LengthAfterRequest == false) {
        this.getProductsNavBar();
      }
    }, 200);

  }
  closeTypesMenu() {
    this.typesMenu = false
  }

  categories: any[] = [];
  categories0LengthAfterRequest: boolean = false
  getCategories = () => {
    this.service.getCategories().subscribe(res => {
      if (res.length == 0) {
        this.categories0LengthAfterRequest = true
      }
      this.categories = res
    })
  }

  getRequestPayLoad = (): FormData => {
    let request = new FormData();
    // request.append("SortType", this.get("SortType").value);
    // request.append("MinPrice", this.get("MinPrice").value);
    // request.append("MaxPrice", this.get("MaxPrice").value);
    if (this.selectedCategoryId) {
      request.append("ProductType", this.selectedCategoryId);
    }
    request.append("PageSize", "4");
    request.append("PageNumber", "1");
    return request as FormData;
  }

  typesData: any[] = [];
  typesData0LengthAfterRequest: boolean = false
  async getProductsNavBar() {
    // if (!this.selectedCategoryId) { return }
    this.service.searchProducts(await this.getRequestPayLoad()).subscribe(
      res => {
        if (res.data.length == 0) {
          this.typesData0LengthAfterRequest = true
        }
        this.typesData = res.data.data
      }
    )
  }

  selectedCategory = 'all'
  selectedCategoryId = null
  navToProductsAndSelectCategory(id, name) {
    if (name == this.selectedCategory) { return }
    this.typesData = [];
    this.selectedCategory = name
    this.selectedCategoryId = id
    setTimeout(() => {
      this.getProductsNavBar();
    }, 150);
  }

  navToProdHandler() {
    this.app.removeScrollPosFromSessionStorage();
    if (this.selectedCategory == 'all') {
      this.app.routeToProducts(null, null)
    } else {
      this.app.routeToProducts(this?.selectedCategory, null)
    }
    if (this.router.url.includes('/products/')) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  navToProductsViaMobile(e) {
    this.app.routeToProducts(e, null)
    this.closeNavBarMobile();
    if (this.router.url.includes('/products/')) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  playSelectionSound() {
    const audio = new Audio('assets/audio/switch1.wav');
    audio.volume = 0.2;
    audio.play();
  }
  cancelSelectionSound() {

  }

  scrollAfterClicking() {
    this.closeNavBarMobile();
    setTimeout(() => {
      this.scrollToElement('firstNj');
    }, 500);
  }

  scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  routeToDetailsRefreshIfSamePage() {
    // [routerLink]="'/' + app?.languageCode+'/product-details/' + item?.productId"
  }
  // TypeMenu

  // AboutMenu
  aboutMenu: boolean = false
  openAboutMenu() {
    this.aboutMenu = true
  }
  closeAboutMenu() {
    this.aboutMenu = false
  }
  // AboutMenu

  // privateFuns
  setLanguage(e) {
    this.app.showLoading();
    setTimeout(() => {
      let x = this.app.getUserLanguage()
      if (e == 'en' && x != 'en') {
        this.router.navigateByUrl(this.router.url.replace('ar', 'en'));
        localStorage.setItem("Language", 'en');
        localStorage.setItem("Direction", 'ltr');
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            window.location.reload();
            // this.app.dismissLoading();
          }, 100);
        }
      } else if (e == 'ar' && x != 'ar') {
        this.router.navigateByUrl(this.router.url.replace('en', 'ar'));
        localStorage.setItem("Language", 'ar');
        localStorage.setItem("Direction", 'rtl');
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            window.location.reload();
            // this.app.dismissLoading();
          }, 100);
        }
      }
    }, 500);

  }
  copyPhoneNumber() {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
    } else {
      this.app.successMsg("تم النسخ!")
    }
    this.clipboard.copy(this?.app?.getCountryInfoLocal()?.phoneNumber.replace(/\s/g, ''));
  }
  notLoggedInDialog() {
    this.router.navigate(['/' + this?.app?.languageCode + '/login']);
  }
  homeThenScrollToFAQs() {
    this.router.navigate(['/' + this?.app?.languageCode + '/home']).then(() => {
      setTimeout(() => {
        const element = document?.getElementById('FAQs');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 900);
    });
  }

  // addScrollListener = () => window.addEventListener('scroll', this.onScrollingForFixedNavBar);
  // removeScrollListener = () => window.removeEventListener('scroll', this.onScrollingForFixedNavBar);
  // showFixedNavBar: boolean = false
  // onScrollingForFixedNavBar = () => {
  //   let scroll = window.scrollY;
  //   if (scroll < 200) {
  //     this.showFixedNavBar = false
  //   } else {
  //     if (scroll > 200) {
  //       this.showFixedNavBar = true
  //     }
  //   }
  // }
  // privateFuns

  @ViewChild('dropDownMobileNavbar', { static: false }) dropDownMobileNavbar: ElementRef;
  closeNavBarMobile() {
    if (this.dropDownMobileNavbar) {
      this.dropDownMobileNavbar.nativeElement.click();
    }
  }


  // CountryOnHeader
  pickedCountry = new FormControl("")
  Countries
  getCounties() {
    this.service.branchesLookUp().subscribe(
      res => {
        if (res.errorCode == 0) {
          this.Countries = res.data
          // CheckCountryInfoLocalStorage
          if (this.app.lastCountryIdPicked()) {
            this.app.checkIfCountryInfo(Number(this.app.lastCountryIdPicked()));
            this.pickedCountry.setValue(Number(this.app.lastCountryIdPicked()))
          } else {
            this.getLocation();
          }
          // if (!this?.app?.getCountryInfoLocal()) {
          // }
        } else if (res.errorCode != 0) {
          this.app.errorMsg(res.errorMessage)
        }

      }
    )
  }

  countryPicked(e) {
    this.app.showLoading();
    localStorage.setItem(environment.appName + "countryIdPicked", e);
    this.app.dismissLoading();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  }



  getLocation() {
    let jordanId = this.Countries.find(x => x.id == 1)
    this.defaultCountryPatch(jordanId.id)
    return
    // debugger
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords.latitude && position.coords.longitude) {
            this.service.getGoogleCord(position.coords.latitude, position.coords.longitude, this.app.languageCode).subscribe(
              res => {
                if (res.status == "OK") {
                  const addressComponents = res.results[0].address_components;
                  let country = addressComponents.find(component =>
                    component.types.includes('country')
                  ).long_name;
                  if (country) {
                    console.warn(this.Countries)
                    console.warn(country)
                    let foundCountry = this.Countries.find(x => x.name.toLowerCase().trim() == country.toLowerCase().trim())
                    if (foundCountry) {
                      this.app.checkIfCountryInfo(foundCountry.id);
                      this.pickedCountry.setValue(foundCountry.id);
                      this.countryPicked(foundCountry.id);
                    } else {
                      console.warn("mapping issue not found")
                      let jordanId = this.Countries.find(x => x.id == 1)
                      this.defaultCountryPatch(jordanId.id)
                    }
                  } else {
                    console.warn("country not found in google.")
                    let jordanId = this.Countries.find(x => x.id == 1)
                    this.defaultCountryPatch(jordanId.id)
                  }
                } else {
                  console.warn("google API error")
                  let jordanId = this.Countries.find(x => x.id == 1)
                  this.defaultCountryPatch(jordanId.id)
                }
              }
            )
          } else {
            console.warn("position lat and long not found")
            let jordanId = this.Countries.find(x => x.id == 1)
            this.defaultCountryPatch(jordanId.id)
          }
        },
        (error) => {
          console.warn("if denied allow", error.code)
          let jordanId = this.Countries.find(x => x.id == 1)
          this.defaultCountryPatch(jordanId.id)
          // switch (error.code) {
          //   case error.PERMISSION_DENIED:
          //     this.error = "User denied the request for Geolocation.";
          //     break;
          //   case error.POSITION_UNAVAILABLE:
          //     this.error = "Location information is unavailable.";
          //     break;
          //   case error.TIMEOUT:
          //     this.error = "The request to get user location timed out.";
          //     break;
          // }
        }
      );
    } else {
      console.warn("navigator.geolocation not found")
      let jordanId = this.Countries.find(x => x.id == 1)
      this.defaultCountryPatch(jordanId.id)
    }
  }

  defaultCountryPatch(id) {
    this.app.checkIfCountryInfo(id);
    this.pickedCountry.setValue(id);
    this.countryPicked(id);
  }
  // CountryOnHeader


}
