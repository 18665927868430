<div [dir]="this.app.getUserDire()" data-aos="fade-in" data-aos-duration="800" data-aos-delay="500">
    <div class="d-flex justify-content-between align-items-center mx-4 mb-2">
        <p *ngIf="hyperPayMode == 'register'" class="mainPfont">{{ 'payment.add' | translate }}</p>
        <p *ngIf="hyperPayMode == 'pay'" class="mainPfont">{{ 'payment.payment' | translate }}</p>
        <mat-icon class="closeIcon" (click)="close()">close</mat-icon>
    </div>
    <!-- action="<*_*>returnUrl<*_*>"  AMEX  -->
    <!-- action="http://216.158.233.68/en/home" -->
    <form style="display: contents;" id="formUrl" [action]="redirectedUrl" class="paymentWidgets cusH"
        data-brands="VISA MASTER">
    </form>
</div>